<template>
  <div>
    <div class="info-tabs">
      <v-tabs v-model="infoTab" color="#00776f" light background-color="white">
        <v-tabs-slider></v-tabs-slider>
        <v-tab href="#client-info">Client Info</v-tab>
        <v-tab href="#care-info">Request Info</v-tab>
        <v-tab href="#client-location" style="padding:0"
          ><v-icon>mdi-map-marker</v-icon></v-tab
        >
      </v-tabs>
      <v-tabs-items
        v-model="infoTab"
        class="info-container"
        :class="{ mapShown: infoTab === 'client-location' }"
      >
        <v-tab-item :key="1" :value="'client-info'">
          <client-info id="client-info" v-bind="about" ref="clientInfo" />
        </v-tab-item>
        <v-tab-item :key="2" :value="'care-info'">
          <request-info id="care-info" v-bind="site()" />
        </v-tab-item>
        <v-tab-item :key="3" :value="'client-location'">
          <div class="map-container">
            <static-map
              :style="{ width: `${mapWidth}px!important` }"
              :lat="lat"
              :lng="lng"
              :maps-key="mapsKey"
              :zoom="zoom"
            />
          </div>
        </v-tab-item>
      </v-tabs-items>
    </div>
  </div>
</template>

<script>
import RequestInfo from "./Request-info.vue"
import ClientInfo from "./Client-info.vue"
import StaticMap from "component-library/src/components/StaticMap/StaticMap.vue"
import { SITE, MAPS_KEY } from "@/config"

export default {
  props: {
    physio: { type: Object, default: () => {} },
    care: { type: Object, default: () => {} },
    about: { type: Object, default: () => {} },
    lat: { type: Number, default: null },
    lng: { type: Number, default: null },
    updatedAt: { type: String, default: null }
  },
  components: {
    "request-info": RequestInfo,
    "client-info": ClientInfo,
    "static-map": StaticMap
  },
  methods: {
    site() {
      let site = {}
      if (SITE === "PHYSIO") {
        site = this.physio
      } else {
        site = this.care
        site.updated_at = this.updatedAt
      }
      return site
    }
  },
  data() {
    return {
      infoTab: null,
      mapsKey: MAPS_KEY,
      mapWidth: 270,
      zoom: 15
    }
  },
  updated() {
    let width = this.$refs.clientInfo?.$el?.clientWidth
    if (width != 0) {
      this.mapWidth = width * 0.8
    }
  }
}
</script>

<style scoped>
.info-tabs {
  height: 100%;
}
.info-container {
  height: calc(100% - 50px);
  overflow-y: auto;
}
#client-info,
#care-info,
#physio-info,
#client-location {
  padding: 5px 0 0 10px;
}
.v-tab {
  text-transform: none !important;
  font-weight: 700;
  font-size: 1.1em;
}

.map-container {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

::v-deep .v-responsive {
  flex: none;
  flex-grow: 0.8;
  flex-basis: auto;
  width: 100% !important;
}

.info-container.mapShown {
  overflow: hidden;
}
</style>
