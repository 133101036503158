import { BillingService } from "@/api/instance"
import { DeAuthSession } from "@/api/de-auth"

export const GetServices = async () => {
  try {
    let {
      data: { services }
    } = await BillingService().get("/service")
    return services
  } catch (e) {
    DeAuthSession(e)
    throw e
  }
}

export const SendService = async service => {
  try {
    await BillingService().post("/service", { service })
  } catch (e) {
    DeAuthSession(e)
    throw e
  }
}

export const UpdateService = async service => {
  try {
    await BillingService().put("/service", { service })
  } catch (e) {
    DeAuthSession(e)
    throw e
  }
}

export const RemoveService = async id => {
  try {
    await BillingService().delete(`/service/${id}`)
  } catch (e) {
    DeAuthSession(e)
    throw e
  }
}
