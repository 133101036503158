<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title
        >Accepted
        <v-tooltip top nudge-top="15" nudge-right="200">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
          </template>
          <span
            >Requests that you have responded to and clients have accepted will
            appear here.</span
          >
        </v-tooltip>
      </v-toolbar-title>
    </v-toolbar>
    <v-card-text>
      <v-data-table
        v-if="requests.length"
        :headers="headers"
        :items="requests"
        :items-per-page.sync="rowsPerPage"
        @click:row="open"
      >
        <template v-slot:item.proposal.postcode="{ item }">
          {{
            item.proposal.postcode
              .substring(0, item.proposal.postcode.length - 3)
              .toUpperCase()
          }}
          {{
            item.proposal.postcode
              .substring(
                item.proposal.postcode.length - 3,
                item.proposal.postcode.length
              )
              .toUpperCase()
          }}
        </template>
        <template v-slot:item.distance="{ item }">
          {{ item.distance.toFixed(1) }}
        </template>

        <template v-slot:item.proposal.accepted_at="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                {{ item.displayAccepted }}
              </span>
            </template>
            <span>{{ item.displayAcceptedTime }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <h3 v-else>You currently have no active requests</h3>
    </v-card-text>
  </v-card>
</template>

<script>
import { GetAccepted } from "@/api/requests/accepted"

import { DateTime } from "luxon"
export default {
  async beforeMount() {
    let requests = await GetAccepted()
    requests.map(x => {
      x.displayAccepted = DateTime.fromJSDate(
        x.proposal.accepted_at
      ).toLocaleString()
      x.displayAcceptedTime = DateTime.fromJSDate(
        x.proposal.accepted_at
      ).toLocaleString(DateTime.DATETIME_FULL)
    })

    this.requests = requests
  },
  methods: {
    colorDistance(distance) {
      if (distance > 25) return "red darken-1"
      else if (distance > 10) return "orange"
      else return "oliveGreen"
    },
    colorDate(date) {
      let now = DateTime.local()
      let elapsed = now.diff(DateTime.fromJSDate(date), "days").days
      if (elapsed > 14) return "red darken-1"
      else if (elapsed > 5) return "orange"
      else return "oliveGreen darken-1"
    },
    open({ proposal: { enquiry_id: id, reference } }) {
      this.$router.push(`response/${id}?reference=${reference}`)
    }
  },
  data() {
    return {
      requests: [],
      headers: [
        { text: "Username", value: "username", groupable: true },
        { text: "Reference", value: "proposal.reference" },
        { text: "Post Code", align: "center", value: "proposal.postcode" },
        { text: "Distance (Miles)", align: "center", value: "distance" },
        {
          text: "Accepted Date",
          align: "center",
          value: "proposal.accepted_at"
        }
      ]
    }
  },
  computed: {
    rowsPerPage() {
      if (window.screen.height <= 720) {
        return 5
      }
      return 10
    }
  }
}
</script>

<style scoped>
.responses {
  height: calc(100vh - (56px + 32px));
}
.list {
  /* height: 35vh; */
  overflow-y: auto;
}
</style>
