<template>
  <div>
    <div class="client-info" v-for="(val, key) in requestInfo" :key="key">
      <h3>{{ titles[key] }}</h3>
      <p>{{ val }}</p>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    requestInfo() {
      let filteredInfo = Object.fromEntries(
        Object.entries(this.$props)
          .filter(([, v]) => v != null && v != "")
          .map(([k, v]) => [k, v === "No" ? "None" : v])
      )
      return filteredInfo
    }
  },
  props: {
    age: { type: Number, default: 0 },
    accommodation: { type: String, default: null },
    postcode: { type: String, default: null },
    // disable lint because this name format is needed to bind object to probs
    //  eslint-disable-next-line vue/prop-name-casing
    medical_condition: { type: String, default: null },
    mobility: { type: String, default: null },
    preferences: { type: String, default: null }
  },
  data() {
    return {
      titles: {
        age: "Age",
        accommodation: "Type of accommodation",
        postcode: "Postcode",
        medical_condition: "Medical Conditions",
        mobility: "Mobility Restrictions",
        preferences: "Care Preferences"
      }
    }
  }
}
</script>

<style scoped>
.client-info {
  margin-bottom: 5px;
}
p {
  margin: 0;
}
</style>
