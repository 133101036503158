<template>
  <div>
    <v-app-bar app fixed class="title-bar">
      <Logo @click="requestsNav" />
      <v-list class="nav-bar">
        <v-menu open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on">
              <v-list-item-action>
                <v-icon>reply_all</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Requests</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item to="/home/requests">
              <v-list-item-action>
                <v-icon>reply_all</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Requests</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/home/responses">
              <v-list-item-action>
                <v-icon>send</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Responded</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/home/accepted">
              <v-list-item-action>
                <v-icon>done_all</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Accepted</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/home/archived">
              <v-list-item-action>
                <v-icon>archive</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Archived</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu open-on-hover bottom offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item v-bind="attrs" v-on="on" v-show="show_services">
              <v-list-item-action>
                <v-icon>toc</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Billing</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </template>
          <v-list>
            <v-list-item to="/home/invoicing">
              <v-list-item-action>
                <v-icon>toc</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Invoicing</v-list-item-title>
              </v-list-item-content>
            </v-list-item>

            <v-list-item to="/home/services">
              <v-list-item-action>
                <v-icon>toc</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Services</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-list-item @click.stop="navMessages" v-show="show_chats">
          <v-badge
            inline
            right
            color="secondary"
            :content="hasUnreadMessages"
            :value="showMessageBadge"
            class="badge-wrapper"
          >
            <v-list-item-action>
              <v-icon>question_answer</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Messages</v-list-item-title>
            </v-list-item-content>
          </v-badge>
        </v-list-item>

        <v-list-item to="/home/profile">
          <v-badge
            inline
            right
            color="secondary"
            :content="hasProfileUpdates"
            :value="showProfileUpdateBadge"
            class="badge-wrapper"
          >
            <v-list-item-action>
              <v-icon>person</v-icon>
            </v-list-item-action>
            <v-list-item-content>
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item-content>
          </v-badge>
        </v-list-item>

        <v-list-item @click="logOut()">
          <v-list-item-action>
            <v-icon>lock</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Log Out</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-app-bar>

    <v-main class="main-content">
      <div class="content-container">
        <router-view v-if="$route.name == 'invoicing'"></router-view>
        <router-view v-else-if="$route.name == 'chat'"></router-view>
        <router-view v-else-if="$route.name == 'requestsById'"></router-view>
        <router-view v-else-if="$route.name == 'responsesById'"></router-view>
        <v-row justif="center" class="fill-height" v-else>
          <v-col
            cols="12"
            sm="10"
            offset-sm="1"
            md="10"
            offset-md="1"
            lg="8"
            offset-lg="2"
            xl="6"
            offset-xl="3"
          >
            <router-view></router-view>
          </v-col>
        </v-row>
      </div>
    </v-main>
  </div>
</template>

<script>
import { SITE, SHOW_CHATS, SHOW_SERVICES } from "@/config"
import { Logout } from "@/api/auth/logout"
import { InitWebsocket } from "@/api/messages/ws"
import { GetChats } from "@/api/messages/get_chats.js"
import { GetCurrentLegalDocuments } from "@/api/legal/get_current_legal_documents.js"
import { GetMessages } from "@/api/messages/get_messages.js"
import { GetProfile } from "@/api/profile"

import Logo from "@/components/logo.vue"

export default {
  name: "Home",
  components: {
    Logo
  },
  data() {
    return {
      dialog: false,
      drawer: null,
      show_chats: SHOW_CHATS,
      show_services: SHOW_SERVICES
    }
  },
  computed: {
    siteName() {
      return SITE.toLowerCase()
    },
    hasUnreadMessages() {
      return this.$store.getters.hasUnreadMessages
    },
    showMessageBadge() {
      return this.$store.getters.hasUnreadMessages > 0
    },
    hasProfileUpdates() {
      return this.$store.getters.hasProfileUpdates
    },
    showProfileUpdateBadge() {
      return this.$store.getters.hasProfileUpdates > 0
    }
  },
  created() {
    const token = this.$store.getters.token
    if (token == null) {
      this.$store.commit("set_token", localStorage.getItem("token"))
    }
    const self = this.$store.getters.self
    if (self == null) {
      this.$store.commit("set_self", localStorage.getItem("self"))
    }
    const last_seen = this.$store.getters.last_seen
    if (last_seen == null) {
      this.$store.commit("set_last_seen", localStorage.getItem("last_seen"))
    }
  },
  async beforeMount() {
    try {
      await GetProfile()

      await GetCurrentLegalDocuments(true)
      await GetChats()
      let chats = this.$store.getters.chats
      for (let chat of chats) {
        await GetMessages(chat.id)
      }
      InitWebsocket()
    } catch (e) {
      console.error(e)
    }
  },
  methods: {
    async navMessages() {
      this.$router.push({
        name: "chat",
        params: { id: this.$store.getters.mostRecentChatID }
      })
    },
    requestsNav() {
      this.$router.push({
        name: "requests"
      })
    },
    async logOut() {
      await Logout()
    }
  }
}
</script>

<style scoped>
.badge-wrapper {
  display: flex;
  flex-direction: row;
}
.logo {
  padding: 5px;
  width: 310px;
  height: 110px;
}
::v-deep .v-app-bar {
  background-color: #fff !important;
  box-shadow: none !important;
  height: 110px !important;
  display: flex;
  justify-content: center;
}
::v-deep .title-bar .v-toolbar__content {
  height: 110px !important;
  width: 1200px;
  justify-content: space-between !important;
}

.content-container {
  width: 95%;
  margin: 15px auto;
}

.title-bar {
  margin-bottom: 30px;
}
.nav-bar {
  display: flex;
}
::v-deep .v-list-item__action {
  margin-right: 8px !important;
}
.list-item {
  color: var(--v-grey-base) !important;
  padding: 8px 16px;
}
.active {
  color: var(--v-primary-base) !important;
}
.nav-bar-icon {
  display: none;
  padding: 0 32px;
}

.main-content {
  height: 100%;
  padding-top: 110px !important;
}

@media only screen and (max-height: 720px) {
  .logo {
    padding: 5px;
    height: 64px;
  }

  .title-bar {
    height: 64px !important;
    margin-bottom: 0;
  }

  ::v-deep .title-bar .v-toolbar__content {
    justify-content: space-between !important;
    padding: 0 50px;
    height: 64px !important;
  }
}

@media only screen and (max-width: 360px) {
  .content-container {
    width: 100%;
    margin: 0;
  }
  .title-bar {
    margin-bottom: 30px;
    padding-left: 0;
  }

  .main-content {
    height: 100%;
    padding-top: 64px;
  }
}
@media print {
  .title-bar {
    visibility: hidden;
  }
}
</style>
