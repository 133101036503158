var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"primary","dark":""}},[_c('v-toolbar-title',[_vm._v("Accepted "),_c('v-tooltip',{attrs:{"top":"","nudge-top":"15","nudge-right":"200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({},'v-icon',attrs,false),on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("Requests that you have responded to and clients have accepted will appear here.")])])],1)],1),_c('v-card-text',[(_vm.requests.length)?_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.requests,"items-per-page":_vm.rowsPerPage},on:{"update:itemsPerPage":function($event){_vm.rowsPerPage=$event},"update:items-per-page":function($event){_vm.rowsPerPage=$event},"click:row":_vm.open},scopedSlots:_vm._u([{key:"item.proposal.postcode",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.proposal.postcode .substring(0, item.proposal.postcode.length - 3) .toUpperCase())+" "+_vm._s(item.proposal.postcode .substring( item.proposal.postcode.length - 3, item.proposal.postcode.length ) .toUpperCase())+" ")]}},{key:"item.distance",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.distance.toFixed(1))+" ")]}},{key:"item.proposal.accepted_at",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(" "+_vm._s(item.displayAccepted)+" ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.displayAcceptedTime))])])]}}],null,false,2537235778)}):_c('h3',[_vm._v("You currently have no active requests")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }