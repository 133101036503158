import Vue from "vue"
import colors from "vuetify/lib/util/colors"
import { WS_BASE_URL, WS_SERVICE_PORT } from "@/config"
import store from "@/store"
import EventBus from "@/event-bus"
import { GetChats } from "@/api/messages/get_chats"

export const InitWebsocket = async () => {
  let socket = new WebSocket(`${WS_BASE_URL}${WS_SERVICE_PORT}/v1/ws`)

  socket.onopen = function() {
    socket.send(
      JSON.stringify({
        created_at: Date.now(),
        type: "authorization",
        token: store.getters.token
      })
    )
    Vue.prototype.$socket = socket
  }

  socket.onmessage = async function(event) {
    let msg = JSON.parse(event.data)

    if (!store.getters.chatExists(msg.chat_id)) {
      await GetChats().catch(err => console.error(err))
    }

    let { id, username } = store.getters.message_sender(
      msg.chat_id,
      msg.sender_id
    )
    if (id !== store.getters.self) {
      EventBus.$emit("toast", {
        text: `New Message from ${username}`,
        type: colors.grey.darken4,
        im: true,
        snack_link: `/home/chat/${msg.chat_id}`
      })
      store.commit("set_chat_has_seen_last", {
        chat_id: msg.chat_id,
        val: false
      })
    }
    store.commit("add_message", { chat_id: msg.chat_id, msg: msg })
    store.commit("set_chat_last_message", { chat_id: msg.chat_id, msg: msg })
  }

  socket.onerror = function(error) {
    console.error(`[error] ${error.message}`)
    socket.close()
  }

  socket.onclose = function() {
    console.log("[close] Connection closed")
    setTimeout(async () => {
      await InitWebsocket()
    }, 1000)
  }
}
