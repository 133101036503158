<template>
  <div class="company-info split-field">
    <div>
      <h2 id="company-name">{{ this.response.company_info.company_name }}</h2>
      <p>{{ this.response.company_info.address }}</p>
    </div>
    <div>
      <div class="split-field">
        <p class="subTitle right">Telephone:</p>
        <p>{{ this.response.company_info.tel }}</p>
      </div>
      <div class="split-field">
        <p class="subTitle right">Email:</p>
        <p>{{ this.response.contact_email }}</p>
      </div>
      <div class="split-field">
        <p class="subTitle right">Website:</p>
        <p>{{ this.response.company_info.website }}</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: { response: { type: Object, default: null } },

  data() {
    return {}
  },
  computed: {},
  methods: {}
}
</script>

<style scoped>
p {
  font-size: 1.5rem;
}
#contact-info * {
  width: 100%;
}
.split-field {
  display: flex;
  flex-direction: row;
  column-gap: 30px;
}
.split-field > div {
  width: 100%;
}
.subTitle {
  font-weight: 600;
  min-width: 35%;
}
.right {
  text-align: right;
}
#company-name {
  font-size: 3rem;
}
@media screen and (max-width: 1919px) {
  p {
    font-size: 1.1rem;
  }
}
@media print {
  p {
    font-size: 1rem !important;
  }
}
</style>
