<template>
  <v-card class="chat_list">
    <div class="chat_header">
      <h2>Messages</h2>
    </div>
    <v-list two-line class="list" v-if="chats.length">
      <v-list-item-group
        v-model="selectedItem"
        active-class="active"
        mandatory
        color="primary"
      >
        <v-list-item
          class="chat_item"
          v-for="chat in chats"
          :class="{ active: chat.ID === chatID }"
          :key="chat.ID"
          ripple
          @click="open(chat.id)"
        >
          <v-list-item-content class="chat_item_content">
            <v-list-item-title>
              {{ getOtherUser(chat.participants).username }}
            </v-list-item-title>
            <v-list-item-subtitle class="chats_content_preview">
              <p class="last_message" v-if="chat.last_message">
                {{ chat.last_message.content }}
              </p>
              <p v-else class="last_message">No Messages Yet</p>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action v-if="chat.unread">
            <v-badge dot color="secondary"></v-badge>
          </v-list-item-action>
        </v-list-item>
      </v-list-item-group>
    </v-list>
    <v-card-text class="unselected-text" v-else>
      <p>You currently have no active chats</p>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    chatID: { type: String, default: null }
  },
  data() {
    return {
      selectedItem: null
    }
  },
  methods: {
    open(id) {
      this.$router.push({
        name: "chat",
        params: { id }
      })
    },
    getOtherUser(participants) {
      for (const [id, participant] of Object.entries(participants)) {
        if (id !== this.self) {
          return participant
        }
      }
    }
  },
  computed: {
    chats() {
      let chats = this.$store.getters.sortedChats

      chats.map(c => {
        if (c?.last_message?.created_at > c?.last_seen && !c?.isOpen) {
          c.unread = true
        } else {
          c.unread = false
        }
      })

      return chats
    },

    self() {
      return this.$store.getters.self
    }
  },
  watch: {
    chats: {
      handler() {
        let chatID = this.chatID
        this.selectedItem = this.chats?.findIndex(c => c.id === chatID)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.unselected-text {
  font-size: 16px;
}

::v-deep .v-list-item {
  width: 100%;
}
.active {
  background-color: transparent;
  z-index: 1;
  color: white;
  height: 80px !important;
}
.active:before {
  border-radius: 4px;
  background-color: var(--v-primary-base);
  opacity: 1 !important;
  z-index: 0;
}
.active * {
  color: white;

  z-index: 1;
}
.chat_header {
  background-color: var(--v-primary-base);
  color: #ffffff;
  height: 60px;
  padding: 5px;
  text-align: center;
  font-size: 0.9rem;
  line-height: 50px;
}
.chat_list {
  margin: 0 auto;
  width: 95%;
  height: fit-content;
}
.list {
  max-height: calc(100vh - 210px);
  overflow: auto;
}
.chat_item {
  height: 64px;
  padding: 0 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.v-list-item__title {
  align-self: flex-start;
}

.chat_item_content {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
  flex: unset;
  width: 100%;
  justify-self: flex-start;
}

.chat_item_content * {
  white-space: pre-wrap;
}

.chats_content_title {
  font-size: 1.4rem;
}

.chats_content_preview {
  color: #808080;
  font-size: 0.9rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding-top: 5px;
}

.active .chats_content_preview {
  border-color: white;
}

.chats_content_preview p {
  margin: 0;
}
.last_message {
  max-width: 300px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.last_message_date {
  float: right;
  font-size: 0.8rem;
}
</style>
