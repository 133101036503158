import { MessageService } from "@/api/instance"
import { DeAuthSession } from "@/api/de-auth"

export const SendMessage = async (chat_id, content) => {
  try {
    await MessageService().post(`/chats/${chat_id}/message`, { content })
  } catch (e) {
    DeAuthSession(e)
    throw e
  }
}
