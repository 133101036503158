import { CompanyLogoutService } from "@/api/instance"
import { DeAuthSession } from "@/api/de-auth"

export const Logout = async () => {
  try {
    await CompanyLogoutService().get("/logout")
    DeAuthSession({ response: { status: 401 } })
  } catch (e) {
    DeAuthSession(e)
    throw e
  }
}
