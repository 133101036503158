<template>
  <div>
    <div class="care-info" v-for="(val, key) in requestInfo" :key="key">
      <h3 v-if="val != null && val.length > 0">{{ titles[key] }}</h3>
      <v-chip-group v-if="Array.isArray(val)" column>
        <v-chip small v-for="(v, i) in val" :key="i">
          <template v-if="key === 'times'">
            {{ formatTime(v.start) }} - {{ formatTime(v.end) }}
          </template>
          <template v-else-if="key === 'days'">
            {{ formatDay(v) }}
          </template>
          <template v-else>
            {{ v }}
          </template>
        </v-chip>
      </v-chip-group>
      <v-chip
        v-else-if="titles[key] === titles.start_date_time"
        :color="colorDate(val)"
        dark
        small
      >
        {{ val }}
      </v-chip>

      <CareType v-else-if="titles[key] === titles.type" :care-type="val" />
      <p v-else-if="titles[key] === titles.updated_at">
        {{ formatLastUpdated(val) }}
      </p>
      <p v-else>{{ val }}</p>
    </div>
  </div>
</template>

<script>
import { DateTime } from "luxon"
import CareType from "@/components/careType.vue"
export default {
  components: {
    CareType
  },
  computed: {
    requestInfo() {
      let filteredInfo = Object.fromEntries(
        Object.entries(this.$props).filter(
          ([, v]) => v != null && v != "Invalid DateTime"
        )
      )

      return filteredInfo
    }
  },
  props: {
    // disable lint because this name format is needed to bind object to probs
    //  eslint-disable-next-line vue/prop-name-casing
    updated_at: { type: String, default: null },
    activities: { type: Array, default: null },
    description: { type: String, default: null },
    duration: { type: String, default: null },
    frequency: { type: String, default: null },
    // disable lint because this name format is needed to bind object to probs
    //  eslint-disable-next-line vue/prop-name-casing
    start_date_time: { type: String, default: null },
    time: { type: String, default: null },
    date: { type: String, default: null },
    location: { type: String, default: null },
    // disable lint because this name format is needed to bind object to probs
    //  eslint-disable-next-line vue/prop-name-casing
    location_preference: { type: String, default: null },
    type: { type: String, default: null },
    days: { type: Array, default: null },
    times: { type: Array, default: null },
    // disable lint because this name format is needed to bind object to probs
    //  eslint-disable-next-line vue/prop-name-casing
    time_info: { type: String, default: null }
  },
  data() {
    return {
      titles: {
        updated_at: "Last updated",
        activities: "Requested Help With",
        description: "Reason for Request",
        duration: "Duration",
        frequency: "Frequency",
        start_date_time: "Requested Start Date",
        date: "Start Date",
        // time: "Start Time",
        location: "Location",
        location_preference: "Location Preference",
        type: "Request Type",
        days: "Requested Days",
        times: "Requested Times",
        time_info: "Further details on days and times"
      }
    }
  },
  methods: {
    colorDate(date) {
      let now = DateTime.local()
      let elapsed = now.diff(DateTime.fromFormat(date, "dd/MM/yyyy"), "days")
        .days
      if (elapsed >= -2) return "red darken-1"
      else if (elapsed < -2 && elapsed >= -7) return "orange"
      else return "oliveGreen darken-1"
    },
    formatTime(timeStamp) {
      let time = DateTime.fromISO(timeStamp)
      return time.toFormat("HH:mm")
    },
    formatLastUpdated(timestamp) {
      let time = DateTime.fromISO(timestamp)
      return time.toFormat("dd/MM/yyyy HH:mm")
    },
    formatDay(dayIndex) {
      let days = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday"
      ]
      return days[dayIndex]
    }
  }
}
</script>

<style scoped>
.care-info {
  margin-bottom: 5px;
}
ul {
  flex-direction: column;
  columns: 2;
  flex-wrap: wrap;
}
p {
  width: 80%;
  margin: 0;
  white-space: pre-line;
}
</style>
