<template>
  <div id="services">
    <h1>Services</h1>
    <hr />
    <div id="services-table">
      <v-data-table
        :headers="headers"
        :items="services"
        fixed-header
        hide-default-footer
        disable-pagination
      >
        <template v-slot:body="props">
          <draggable
            :list="props.items"
            tag="tbody"
            class="dragArea"
            :group="{ name: 'services', pull: 'clone', put: false }"
            :clone="cloneService"
            :disabled="disabled"
          >
            <tr v-for="(item, index) in props.items" :key="index">
              <td>{{ item.name }}</td>
              <td>{{ item.description }}</td>
              <td>{{ timePeriod(item.time_period) }}</td>
              <td>{{ item.from_price }}</td>
            </tr>
          </draggable>
        </template>
      </v-data-table>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable"
import { timePeriod } from "@/time-period.js"
import { GetServices } from "@/api/billing/service.js"

export default {
  components: { draggable },
  props: { disabled: { type: Boolean } },
  data() {
    return {
      headers: [
        { text: "Name", value: "name", width: "20%" },
        {
          text: "Description",
          value: "description",
          width: "45%"
        },
        {
          text: "Time Period",
          value: "time_period",
          width: "15%",
          sortable: false
        },

        { text: "Price", value: "price", width: "20%" }
      ],
      services: [],
      invoiceID: 0
    }
  },
  async created() {
    this.services = await GetServices()
  },

  computed: {},
  methods: {
    cloneService({ name, description, from_price, to_price, time_period }) {
      return {
        id: this.invoiceID++,
        name: name,
        description: description,
        from_price: from_price,
        to_price: to_price,
        time_period: time_period,
        quantity: 1
      }
    },
    timePeriod(time) {
      return timePeriod(time)
    }
  }
}
</script>

<style scoped>
#invoice-container {
  display: grid;
  grid-template-areas: "services invoice";
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: calc(100vh - 210px);
  gap: 10px;
}
#invoice-container > div {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 15px;
}
#services-table {
  height: calc(100vh - 275px);
}
#services-table ::v-deep .v-data-table__wrapper {
  height: calc(100vh - 275px);
}
#invoice-generator {
  grid-area: invoice;
  overflow-y: auto;
}
@media print {
  #invoice-container {
    visibility: hidden;
  }
}
</style>
