<template>
  <div class="split-field">
    <div>
      <div class="split-field input print-hidden">
        <p class="subTitle">Select client:</p>
        <v-autocomplete
          name="username"
          :items="usernames"
          outlined
          v-model="selectedClient"
          :menu-props="{ maxHeight: 500 }"
          hide-details
        ></v-autocomplete>
      </div>
      <div class="split-field">
        <p class="subTitle">Name:</p>
        <p>{{ this.client.name }}</p>
      </div>
      <div class="split-field">
        <p class="subTitle">Address:</p>
        <p>{{ this.client.address }}</p>
      </div>
    </div>
    <div>
      <div class="split-field input">
        <p class="subTitle">
          Invoice Reference:
        </p>
        <v-text-field
          name="Reference"
          v-model="invoice.reference"
          type="text"
          append-icon="mdi-auto-fix"
          @click:append="generateRef"
          outlined
          hide-details
          class="print-hidden"
        ></v-text-field>
        <p class="print-show">{{ this.invoice.reference }}</p>
      </div>
      <div class="split-field">
        <p class="subTitle">Proposal Reference:</p>
        <p>{{ this.invoice.proposalRef }}</p>
      </div>
      <div class="split-field">
        <p class="subTitle">Issue Date:</p>
        <p>{{ this.invoice.issueDate }}</p>
      </div>
      <div class="split-field input">
        <p class="subTitle">Due Date:</p>
        <v-dialog
          ref="dialog"
          v-model="calendar"
          :return-value.sync="invoice.dueDate"
          persistent
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="invoice.dueDate"
              append-icon="mdi-calendar"
              readonly
              placeholder="Select Date"
              outlined
              v-bind="attrs"
              v-on="on"
              hide-details
              class="print-hidden"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="invoice.dueDate"
            no-title
            show-adjacent-months
            event-color="secondary"
            :min="new Date().toISOString().substr(0, 10)"
            color="primary"
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="calendar = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(invoice.dueDate)"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
        <p class="print-show">{{ this.invoice.dueDate }}</p>
      </div>
    </div>
  </div>
</template>
<script>
import { customAlphabet } from "nanoid"
import { DateTime } from "luxon"

export default {
  props: {
    usernames: { type: Array, default: null },
    accepted: { type: Array, default: null }
  },
  data() {
    return {
      client: { name: "", address: "" },
      invoice: {
        reference: "",
        proposalRef: "",
        issueDate: DateTime.local().toISODate(),
        dueDate: ""
      },
      selectedClient: "",
      calendar: false
    }
  },
  computed: {},
  methods: {
    generateRef() {
      const nanoid = customAlphabet("1234567890abcdef", 8)
      let ref = nanoid()
      this.invoice.reference = ref
    }
  },
  watch: {
    selectedClient(username) {
      this.$emit("client-selected", true)
      this.accepted.forEach(client => {
        if (client.username === username) {
          this.client.name = client.first_name + " " + client.last_name
          this.client.address = client.address
          this.invoice.proposalRef = client.proposal.reference
        }
      })
    },
    invoice: {
      handler() {
        if (
          this.client.name !== "" &&
          this.invoice.reference !== "" &&
          this.invoice.dueDate != ""
        ) {
          this.$emit("invoice-info-completed", true)
        }
      },
      deep: true
    }
  }
}
</script>

<style scoped>
p {
  font-size: 1.5rem;
}
.subTitle {
  font-weight: 600;
  min-width: 35%;
}

.split-field {
  display: flex;
  flex-direction: row;
  column-gap: 30px;
}
.split-field > div {
  width: 100%;
}
.input {
  margin: 0 0 20px 0;
}

.input p {
  margin: 0;
  align-self: center;
}
.print-show {
  display: none;
}

@media print {
  .print-hidden {
    display: none;
  }
  .print-show {
    display: block;
  }
  p {
    font-size: 1rem !important;
  }
}
@media screen and (max-width: 1919px) {
  p {
    font-size: 1.1rem;
  }
}
</style>
