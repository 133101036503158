<template>
  <div>
    <div class="header">
      <div>
        <h2>Proposal</h2>

        <p class="input-hint">
          Please note that information in your profile (company name, bio,
          website, CQC profile) will be automatically sent across to the client
          once you submit a proposal.
        </p>
      </div>
      <div
        style="display:flex; flex-direction:row; gap: 1em; margin-left:10px;"
      >
        <v-btn v-if="editing" dark color="grey" @click="exit">
          Stop Editing
        </v-btn>
        <archive v-if="!editing" />
      </div>
    </div>
    <v-form ref="form">
      <div id="form">
        <div id="intro">
          <label id="intro">
            <p class="required-label has-hint">How can you help?</p>
            <p class="input-hint">
              Please explain in as much detail as possible how you can help this
              client.
            </p>
            <v-textarea
              name="intro"
              v-model="response.intro"
              auto-grow
              counter
              rows="1"
              row-height="10"
              outlined
              :rules="[rules.required, rules.noTel, rules.noEmail]"
            ></v-textarea>
          </label>
        </div>
        <label id="date-picker">
          <p
            class="label has-hint"
            :class="{ required: this.dateRequiredText }"
          >
            What date can you start? <br />
          </p>
          <p class="input-hint">
            <v-badge
              dot
              inline
              left
              :color="colorDate(clientStartDate)"
            ></v-badge>
            Client requested: {{ clientStartDate }}
          </p>

          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="response.start_date"
            transition="scale-transition"
            offset-y
            min-width="auto"
            id="date-picker"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="response.start_date"
                append-icon="mdi-calendar"
                readonly
                placeholder="Select Date"
                outlined
                v-bind="attrs"
                v-on="on"
                :rules="[rules.required]"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="response.start_date"
              no-title
              show-adjacent-months
              :events="datePickerEvents"
              event-color="secondary"
              :min="new Date().toISOString().substr(0, 10)"
              color="primary"
              scrollable
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false"> Cancel </v-btn>
              <v-btn
                text
                color="primary"
                @click="$refs.menu.save(response.start_date)"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </label>
        <label id="cost">
          <p class="required-label has-hint">Estimated cost</p>
          <p class="input-hint">
            Please include the time period e.g. per week, per hour
          </p>
          <v-text-field
            name="cost"
            type="text"
            v-model="response.cost"
            :rules="[rules.required]"
            outlined
            width="50%"
          ></v-text-field>
        </label>
        <label id="ref-code">
          <p class="required-label has-hint">Reference code</p>
          <p class="input-hint">
            This is shared with the client after connecting
          </p>
          <v-text-field
            id="ref-code"
            name="referenceNo"
            v-model="reference"
            type="text"
            append-icon="mdi-auto-fix"
            @click:append="generateRef"
            outlined
            :rules="[rules.required]"
          ></v-text-field>
        </label>
        <v-btn v-if="!editing" id="submit" color="primary" @click="respond">
          Submit proposal
        </v-btn>
        <v-btn v-if="editing" id="submit" color="primary" @click="update">
          Update proposal
        </v-btn>
      </div>
    </v-form>
  </div>
</template>

<script>
import { Respond, UpdateResponse, GetResponse } from "@/api/requests/responses"

import Archive from "./Archive.vue"
import { SITE, SHOW_CQC } from "@/config"
import { GetProfile } from "@/api/profile"
import { customAlphabet } from "nanoid"
import { DateTime } from "luxon"
import { noEmail, noTel } from "@/rules/rules"
import EventBus from "@/event-bus"

export default {
  components: {
    archive: Archive
  },
  props: {
    clientStartDate: { type: String, default: null },
    editing: { type: Boolean, default: false }
  },
  computed: {
    datePickerEvents() {
      if (this.clientStartDate == null) return undefined
      return [
        DateTime.fromFormat(this.clientStartDate, "dd/MM/yyyy").toISODate()
      ]
    }
  },
  data() {
    return {
      menu: false,
      show_cqc: SHOW_CQC,
      site: SITE,
      reference: null,
      cqc_ratings: [
        {
          text: "Outstanding",
          value: "outstanding",
          icon: "star"
        },
        {
          text: "Good",
          value: "good",
          icon: "star"
        },
        {
          text: "Outstanding",
          value: "outstanding",
          icon: "star"
        },
        {
          text: "Outstanding",
          value: "outstanding",
          icon: "star"
        }
      ],
      response: {
        intro: "",
        reference: "",
        company_info: {
          logo: ""
        }
      },
      dateRequiredText: false,
      rules: {
        required: value => !!value || "Required",
        email: value => {
          const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
          return pattern.test(value) || "Invalid email"
        },
        noTel,
        noEmail
      }
    }
  },
  async beforeMount() {
    if (this.editing) {
      const response = await GetResponse(this.$route.params.id)
      this.response = response

      this.response.start_date = DateTime.fromJSDate(
        new Date(response.start_date)
      ).toFormat("yyyy-MM-dd")
      this.reference = response.reference
    } else {
      await this.getProfile()
    }
  },
  methods: {
    colorDate(date) {
      if (date == null) return "grey lighten-2"

      let now = DateTime.local()
      let elapsed = now.diff(DateTime.fromFormat(date, "dd/MM/yyyy"), "days")
        .days
      if (elapsed >= -2) return "red darken-1"
      else if (elapsed < -2 && elapsed >= -7) return "orange"
      else return "oliveGreen darken-1"
    },
    generateRef() {
      const nanoid = customAlphabet("1234567890abcdef", 8)
      let ref = nanoid()
      this.reference = ref
    },
    async getProfile() {
      await GetProfile()
      let data = this.$store.getters.profile
      this.response = {
        intro: "",
        enquiry_id: this.$route.params.id,
        company_info: {
          bio: data.company.bio,
          company_name: data.company.company_name,
          logo: data.company.logo,
          tel: data.company.tel,
          website: data.company.website,
          cqc_profile: data.company.cqc_profile
        },
        contact_email: data.email
      }

      if (this.show_cqc) {
        this.response.company_info.cqc_rating = data.company.cqc_rating
      }

      this.profile_types_of_care = data.types_of_care
    },
    async respond() {
      if (this.response.start_date == null) {
        this.dateRequiredText = true
      } else {
        this.dateRequiredText = false
      }
      if (this.$refs.form.validate() && this.dateRequiredText === false) {
        let data = this.response
        // To have the input be reactive when a reference is generated it must be a top level data primitive
        // thus we must set it back into the response at submission time.
        data.reference = this.reference
        data.start_date = new Date(data.start_date)

        try {
          await Respond(this.$route.params.id, data)
          this.$emit("responded")
        } catch (e) {
          data.start_date = data.start_date.toString()
          throw e
        }
      }
    },
    async update() {
      console.log("intro", this.response.intro)
      console.log("cost", this.response.cost)
      console.log("start_date", this.response.start_date)
      console.log("reference", this.reference)

      try {
        if (this.response.start_date == null) {
          this.dateRequiredText = true
        } else {
          this.dateRequiredText = false
        }
        if (this.$refs.form.validate() && this.dateRequiredText === false) {
          let data = this.response
          // To have the input be reactive when a reference is generated it must be a top level data primitive
          // thus we must set it back into the response at submission time.
          data.reference = this.reference
          data.start_date = new Date(data.start_date)
          await UpdateResponse(data)
          // event bus success
          EventBus.$emit("toast", {
            text: `Proposal Updated`,
            type: "success"
          })
          this.exit()
        }
      } catch (e) {
        console.error(e)
      }
    },
    exit() {
      this.$emit("editing", false)
    }
  }
}
</script>

<style scoped>
.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
h2 {
  display: inline;
}
.help-icon {
  margin-left: 5px;
  margin-bottom: 5px;
}

#archive {
  display: inline;
}
#form {
  width: 100%;
  display: grid;
  grid-template-areas:
    "intro intro"
    "cost datePicker"
    "refCode timePicker"
    ". submit";

  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  gap: 0px 30px;
}

#intro {
  grid-area: intro;
}

#date-picker {
  grid-area: datePicker;
  width: 100%;
}

#time {
  margin-top: 10px;
  grid-area: timePicker;
  align-self: flex-end;
}
#cost {
  grid-area: cost;
  align-self: flex-end;
}
#ref-code {
  grid-area: refCode;
  align-self: flex-end;
}
#contact {
  align-self: flex-end;

  grid-area: contact;
}

#submit {
  grid-area: submit;
  display: inline;
  width: fit-content;
  justify-self: flex-end;
  margin-bottom: 10px;
}

.v-text-field {
  padding-top: 0;
}
.intro {
  width: 400px;
}

.has-hint {
  margin-bottom: 0;
}

::v-deep .input-hint {
  color: var(--v-grey-lighten1);
}

.half-n-half {
  display: flex;
  flex-direction: row;
  gap: 30px;
}

.half-n-half * {
  width: 50%;
}
</style>
