<template>
  <div id="archive">
    <v-dialog
      v-model="dialog"
      persistent
      max-width="600px"
      @click:outside="dialog = false"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn dark v-bind="attrs" v-on="on" color="#c75e40">
          Archive
        </v-btn>
      </template>
      <v-card class="archive-dialog">
        <h2>Archive this request</h2>
        <p>
          This will remove the request from the main requests list and
          unsubscribe you from notifications from this request
        </p>
        <p>
          If you wish to unarchive this request and respond later please contact
          our help desk using the icon in the bottom right of your screen.
        </p>
        <v-form ref="form">
          <v-select
            label="Reason for Archiving"
            v-model="reason"
            outlined
            :items="reasons"
            :rules="[rules.required]"
          >
          </v-select>
          <v-btn color="#c75e40" dark @click="archive"> Archive </v-btn>
        </v-form>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { ArchiveRequest } from "@/api/requests/archived"

export default {
  data() {
    return {
      dialog: false,
      rules: {
        required: value => !!value || "Required"
      },
      // Reasons for archiving
      reasons: [
        "We do not provide the type of care requested ",
        "We do not currently have the capacity/carers available to help",
        "The location is too far away",
        "The care request doesn’t give enough information for us to make an offer",
        "Other"
      ],
      reason: null
    }
  },
  methods: {
    async archive() {
      if (this.$refs.form.validate()) {
        await ArchiveRequest(this.$route.params.id, this.reason)
        this.$router.push("home/requests")
      }
    }
  }
}
</script>

<style scoped>
.archive-dialog {
  padding: 20px;
}

#archive p {
  font-size: 0.9em !important;
}
#archive {
  padding-bottom: 10px;
}
</style>
