<template>
  <div class="wrapper">
    <div id="proposal-info">
      <div class="header">
        <h2>Proposal</h2>
        <div style="display:flex; flex-direction:row; gap: 1em;">
          <v-btn dark color="green" @click="edit">
            edit
          </v-btn>
          <archive />
        </div>
      </div>
      <div class="proposal-preface ">
        <strong> Preface </strong>
        <pre>{{ response.intro }}</pre>
      </div>
      <div class="small-info">
        <div>
          <strong>Proposed start date</strong>
          <p>{{ response.start_date }}</p>
        </div>
        <div v-if="response.start_time">
          <strong>Proposed start time</strong>
          <p>{{ response.start_time }}</p>
        </div>
        <div>
          <strong>Cost</strong>
          <p>{{ response.cost }}</p>
        </div>
        <div>
          <strong>Reference</strong>
          <p>{{ response.reference }}</p>
        </div>
      </div>

      <div class="contact" v-if="clientEmail != null && clientEmail.length > 0">
        <h3>Contact Details</h3>
        <div class="contact-details">
          <div>
            <strong>Email Address</strong>
            <p>{{ clientEmail }}</p>
          </div>
          <div>
            <strong>Telephone Number</strong>
            <p>{{ clientTel }}</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Archive from "./Archive.vue"
import { GetResponse } from "@/api/requests/responses.js"

export default {
  components: {
    archive: Archive
  },
  props: {
    clientEmail: {
      type: String,
      default: null
    },
    clientTel: { type: String, default: null }
  },
  data() {
    return {
      response: {}
    }
  },
  async beforeMount() {
    this.response = await GetResponse(this.$route.params.id)
  },
  methods: {
    edit() {
      this.$emit("edit", true)
    }
  }
}
</script>

<style scoped>
.wrapper {
  height: 100%;
}
#proposal-info {
  height: 100%;
  max-height: 100%;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: 30px min-content 100px;
  gap: 10px;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.small-info {
  display: flex;
  flex-direction: row;
  padding-top: 20px;
  justify-content: space-evenly;
}

.proposal-preface {
  grid-column: 1/-1;
}

.proposal-preface pre {
  word-wrap: break-word;
  white-space: pre-line;
  font-family: inherit;
  overflow-y: auto;
  height: fit-content;
  max-height: 400px;
}

.contact {
  background-color: #def8e0;
  padding: 20px;
  border-radius: 5px;
}
.contact-details {
  padding-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
</style>
