<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-toolbar-title class="title"
        >Customer {{ $route.params.id }}</v-toolbar-title
      >
    </v-toolbar>
    <v-tabs
      v-model="active"
      background-color="primary"
      dark
      slider-color="white"
    >
      <v-tab>About</v-tab>
      <v-tab v-if="site === 'CARE'">Care</v-tab>
      <v-tab v-if="site === 'PHYSIO'">Physio</v-tab>
    </v-tabs>
    <v-container>
      <section name="about" v-if="active === 0">
        <h2>About</h2>
        <strong>Age</strong>
        <p>{{ request.about.age }}</p>
        <strong>Type of accommodation</strong>
        <p>{{ request.about.accommodation }}</p>
        <template v-if="site === 'CARE'">
          <strong>
            Do you have any preferences about the person delivering your care
            (e.g. male or female, language spoken, specific expertise)?
          </strong>
          <p>{{ request.about.medicine }}</p>
        </template>
        <strong>
          Do you have any medical conditions we should be aware of?
          <br />(We can try to accomodate these)
        </strong>
        <p>{{ request.about.medical_condition }}</p>
        <strong>
          Do you have any mobility restrictions?
          <br />(if yes, please state)
        </strong>
        <p>{{ request.about.mobility }}</p>
      </section>

      <section name="care" v-if="active === 1 && site === 'CARE'">
        <h2>Care</h2>
        <strong>
          In a few words, why do you need care
          <br />(e.g. ‘support at home whilst recovering from operation’)
        </strong>
        <p>{{ request.care.description }}</p>
        <strong>What date would you like care to start?</strong>
        <p>{{ request.care.start_date_time }}</p>
        <strong>How often would you like a carer to visit you?</strong>
        <p>{{ request.care.frequency }}</p>
        <strong>How often would you like a carer to visit you?</strong>
        <p>{{ request.care.frequency }}</p>
        <strong>How long would you like care for?</strong>
        <p>{{ request.care.duration }}</p>
        <strong>What activities will you need help with?</strong>
        <ul>
          <li v-for="activity in request.care.activities" :key="activity">
            {{ activity }}
          </li>
        </ul>
      </section>

      <section name="physio" v-if="active === 1 && site === 'PHYSIO'">
        <h2>Physio</h2>
        <strong>
          In a few words, why do you need physiotherapy
          <br />(e.g. ‘just had a knee replacement’, ‘back pain’)
        </strong>
        <p>{{ request.physio.description }}</p>
        <strong>What date would you like physiotherapy to start?</strong>
        <p>
          {{ new Date(request.physio.date).getDate() }}/{{
            new Date(request.physio.date).getMonth() + 1
          }}/{{ new Date(request.physio.date).getFullYear() }}
        </p>
        <div v-if="request.physio.time != null">
          <strong>
            What time would you like physiotherapy to start?
            <br />(If known)
          </strong>
          <p>{{ request.physio.time }}</p>
        </div>
        <strong>Where would you like your physiotherapy to take place?</strong>
        <p>
          {{ request.physio.location }}
          <span v-if="request.physio.location_preference"
            >- {{ request.physio.location_preference }}</span
          >
        </p>
        <strong>
          Do you have health insurance or are you paying for physiotherapy
          yourself?
        </strong>
        <p>{{ request.physio.payment }}</p>
      </section>
    </v-container>
  </v-card>
</template>

<script>
import { SITE } from "@/config"
import { GetRequest } from "@/api/requests/requests"
import { UnarchiveRequest } from "@/api/requests/archived"
export default {
  data() {
    return {
      site: SITE,
      active: 0,
      request: {
        user: {
          postcode: ""
        },
        about: {
          accommodation: "",
          preferences: ""
        },
        careAnswers: {},
        physioAnswers: {}
      },
      response: {
        contact: {}
      }
    }
  },
  async beforeMount() {
    this.request = await GetRequest(this.$route.params.id)
  },
  methods: {
    async unarchive() {
      await UnarchiveRequest(this.$route.params.id)
      this.$router.push("home/archived")
    }
  }
}
</script>

<style scoped>
.loading {
  background-color: cadetblue;
}

li {
  list-style: none;
}

h2 {
  padding-bottom: 10px;
}
</style>
