import { RequestService } from "@/api/instance"
import { DeAuthSession } from "@/api/de-auth"

export const GetAccepted = async () => {
  try {
    let {
      data: { proposals }
    } = await RequestService().get("/proposals/accepted")
    if (proposals != null && proposals.length > 0) {
      for (let p of proposals) {
        p.proposal.created_at = new Date(p.proposal.created_at)
        p.proposal.accepted_at = new Date(p.proposal.accepted_at)
      }
      return proposals
    }
    return []
  } catch (e) {
    DeAuthSession(e)

    throw e
  }
}
