<template>
  <v-card
    ><v-card-title class="service-header"
      ><span id="service-management-title">Service Management</span>
      <v-btn icon color="white" @click="closeManagementDialog">
        <v-icon>mdi-close</v-icon>
      </v-btn></v-card-title
    >
    <v-card-text class="service-management-form">
      <v-form ref="form">
        <p class="input-label">Name</p>
        <v-text-field
          v-model="localItem.name"
          name="name"
          :rules="[rules.required]"
          outlined
        ></v-text-field>
        <p class="input-label">Description</p>
        <v-textarea
          v-model="localItem.description"
          name="description"
          :rules="[rules.required]"
          outlined
        ></v-textarea>
        <div class="split-inputs">
          <div>
            <p class="input-label">Price</p>

            <v-row no-gutters>
              <v-col cols="2" v-if="localPrice == true"
                ><v-subheader class="price-heading">
                  From:
                </v-subheader></v-col
              >
              <v-col cols="6">
                <v-text-field
                  v-model.number="localItem.from_price"
                  class="price-input"
                  name="fromPrice"
                  outlined
                  prepend-inner-icon="mdi-currency-gbp"
                  :rules="[rules.required, rules.number]"
                ></v-text-field>
              </v-col>
              <v-col cols="2">
                <v-checkbox
                  v-model="localPrice"
                  class="range-toggle"
                  label="Range"
                ></v-checkbox>
              </v-col>
            </v-row>
            <v-row no-gutters v-if="localPrice == true">
              <v-col cols="2"
                ><v-subheader class="price-heading"> To: </v-subheader></v-col
              >
              <v-col cols="6">
                <v-text-field
                  v-model.number="localItem.to_price"
                  class="price-input"
                  name="toPrice"
                  outlined
                  prepend-inner-icon="mdi-currency-gbp"
                  :rules="[rules.required, rules.number]"
                ></v-text-field>
              </v-col>
            </v-row>
          </div>

          <div>
            <p class="input-label">Time Period</p>
            <v-row no-gutters>
              <v-col cols="12"
                ><v-select
                  v-model="localItem.time_period"
                  :items="timePeriods"
                  outlined
                  :rules="[rules.required]"
                ></v-select
              ></v-col>
            </v-row>
          </div>
        </div>

        <div>
          <v-row justify="end" class="management-btn">
            <v-btn color="lightGrey" id="clear-btn" @click="close">Clear</v-btn>
            <v-btn color="primary" @click="save"
              >{{ managementButton }} Service</v-btn
            >
          </v-row>
        </div>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { timePeriod, timePeriodStore } from "@/time-period.js"

export default {
  props: {
    edited: {
      type: Object,
      default: () => {}
    },
    price: { type: Boolean }
  },
  data() {
    return {
      localPrice: false,
      closeDialog: false,
      timePeriods: [
        "One Off Payment",
        "Per Hour",
        "Per Day",
        "Per Week",
        "Per Month"
      ],
      rules: {
        required: value => !!value || "Required",
        number: v =>
          Number.isInteger(Number(v)) || "The value must be an integer number"
      }
    }
  },
  mounted() {
    if (
      this.localItem.to_price != 0 &&
      JSON.stringify(this.localItem) !== "{}"
    ) {
      this.localPrice = true
    }
  },
  watch: {
    price: function() {
      this.localPrice = this.price
    },
    edited: function() {
      if (typeof this.localItem.time_period === "number") {
        this.localItem.time_period = this.timePeriod(this.localItem.time_period)
      }
    }
  },

  computed: {
    managementButton() {
      if (this.localItem.id === undefined) {
        return "Create"
      } else {
        return "Edit"
      }
    },
    localItem: {
      get: function() {
        return this.edited
      },
      set: function() {
        this.localItem = this.edited
      }
    }
  },

  methods: {
    close() {
      this.$refs.form.reset()
      this.localItem.id = undefined
      this.localPrice = false
      this.$emit("resetPrice", this.localPrice)
    },

    async save() {
      if (this.$refs.form.validate()) {
        this.localItem.time_period = timePeriodStore(this.localItem.time_period)

        if (this.localPrice === false) {
          this.localItem.to_price = 0
        }
        await this.$emit("newEditedItem", this.localItem)
        this.localPrice = false
        this.close()
      }
    },
    timePeriod(value) {
      return timePeriod(value)
    },
    closeManagementDialog() {
      this.close()
      this.closeDialog = true
      this.$emit("closeDialog", this.closeDialog)
    }
  }
}
</script>

<style scoped>
/* Service Management */

.service-management-form {
  margin-top: 10px;
}

.service-header {
  background-color: var(--v-primary-base);
  justify-content: space-between;
}

#service-management-title {
  color: #ffffff;
  padding: 0 15px;
  font-size: 1.25rem;
  font-weight: 400;
  justify-content: space-between;
}

.split-inputs {
  display: flex;
  justify-content: space-between;
}

.input-label {
  font-size: 18px;
}

.range-toggle {
  margin-left: 5px;
}

.time-heading {
  font-size: 1rem;
  padding: 0;
}

.price-heading {
  padding: 15px 0 0 0;
  font-size: 16px;
}

.management-btn {
  margin: 15px 0 0 0;
}

#clear-btn {
  margin-right: 10px;
}
</style>
