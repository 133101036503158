<template>
  <div id="invoice-container">
    <serviceList :disabled="disabled" />
    <invoice id="invoice-generator" @client-selected="clientSelected" />
  </div>
</template>

<script>
import Invoice from "@/components/billing/invoice.vue"
import ServiceList from "@/components/billing/service-list.vue"

export default {
  components: { invoice: Invoice, serviceList: ServiceList },
  data() {
    return {
      disabled: true
    }
  },
  computed: {},
  methods: {
    clientSelected(value) {
      this.disabled = !value
    }
  }
}
</script>

<style scoped>
#invoice-container {
  display: grid;
  grid-template-areas: "services invoice";
  grid-template-columns: 0.5fr 1fr;
  grid-template-rows: calc(100vh - 210px);
  gap: 10px;
}
#invoice-container > div {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 15px;
}
#invoice-generator {
  grid-area: invoice;
  overflow-y: auto;
}
@media print {
  #invoice-container {
    visibility: hidden;
  }
}
</style>
