import { MessageService } from "@/api/instance"
import { DeAuthSession } from "@/api/de-auth"
import store from "@/store"

export const GetChats = async () => {
  try {
    let {
      data: { chats }
    } = await MessageService().get("/chats")
    store.commit("set_chats", chats)
  } catch (e) {
    DeAuthSession(e)
    throw e
  }
}
