<template>
  <div>
    <h2>Send a message to this client.</h2>
    <p>
      If you need to clarify something with the client, you can do so by sending
      them a message.<br />
      Please remember <strong>never</strong> ask for or reveal any contact
      information.
    </p>
    <v-form ref="form" @submit.prevent="sendMessage" lazy-validation>
      <div class="spread-em">
        <v-dialog
          v-model="dialog"
          width="500"
          content-class="custom-chat-dialog"
        >
          <template v-slot:activator="{ attrs }">
            <v-btn color="secondary" v-bind="attrs" @click="openChat"
              >Open Chat Window</v-btn
            >
          </template>

          <chatbox :chat-i-d="chat_id" @close="closeChat" />
        </v-dialog>
      </div>
    </v-form>
  </div>
</template>

<script>
import { GetMessages } from "@/api/messages/get_messages.js"
import { AddChat } from "@/api/messages/add_chat.js"
import { GetChats } from "@/api/messages/get_chats.js"
import Chatbox from "../chat/chatbox.vue"

export default {
  components: {
    chatbox: Chatbox
  },
  props: {
    userID: { type: String, default: null }
  },
  data() {
    return {
      openDialog: false,
      message: "",
      rules: {
        required: value => !!value || "Required",
        email: value => {
          const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
          return pattern.test(value) || "Invalid email"
        }
      }
    }
  },
  methods: {
    closeChat() {
      this.openDialog = false
    },
    async openChat() {
      if (this.chat_id == null) {
        await AddChat(this.userID)
        await GetChats()
      }
      this.openDialog = true
    },
    async open(id) {
      await GetMessages(id)
      this.$router.push({
        name: "chat",
        params: { id }
      })
    }
  },
  computed: {
    dialog: {
      get() {
        return this.openDialog && this.chat_id != null
      },
      set() {
        this.openDialog = false
      }
    },
    chat_id() {
      let chat_id = this.$store.getters.checkChats(
        this.userID,
        this.$store.getters.self
      )
      return chat_id
    }
  }
}
</script>

<style scoped>
::v-deep .custom-chat-dialog {
  align-self: flex-start;
  margin-left: auto;
  margin-top: 80px;
  margin-right: 75px;
}

p {
  font-size: 0.9em;
}

.spread-em {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
}
</style>
