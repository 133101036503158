import { MessageService } from "@/api/instance"
import { DeAuthSession } from "@/api/de-auth"
import store from "@/store"

export const GetMessages = async chat_id => {
  try {
    let {
      data: { messages }
    } = await MessageService().get(`/chats/${chat_id}/messages`)

    store.commit("set_messages", { chat_id, msgs: messages })
  } catch (e) {
    DeAuthSession(e)
    throw e
  }
}
