<template>
  <div class="invoice">
    <div class="split-field">
      <h1>Invoice</h1>
      <v-stepper
        class="elevation-0 print-hidden"
        style="background: none; width:400px;"
        v-model="invoiceStatus"
      >
        <v-stepper-header>
          <v-stepper-step
            step=""
            :complete="invoiceStatus >= 1"
            :class="{ completed: invoiceStatus > 1 }"
          >
            Draft
            <small>{{ invoice.updatedAt }}</small>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step
            step=""
            :complete="invoiceStatus >= 2"
            :class="{ completed: invoiceStatus > 2 }"
            :rules="[overdue]"
          >
            Sent<small>{{ invoice.sentAt }}</small>
          </v-stepper-step>

          <v-divider></v-divider>

          <v-stepper-step step="" :complete="invoiceStatus == 3">
            Paid<small>{{ invoice.paidAt }}</small>
          </v-stepper-step>
        </v-stepper-header>
      </v-stepper>
    </div>
    <hr />
    <companyInfo :response="this.response" />
    <hr />
    <h2 class="title" id="billTo">Bill to</h2>
    <hr class="half-width" />
    <invoiceInfo
      :usernames="this.usernames"
      :accepted="this.accepted"
      v-on="$listeners"
      @invoice-info-completed="completedInvoiceInfo"
    />
    <hr />
    <invoiceItems @invoice-items-length="invoiceItemsList" />
    <div class="split-field">
      <v-spacer></v-spacer>
      <v-btn
        color="lightGrey"
        class="print-hidden"
        @click="print"
        :disabled="incompleteInvoice"
      >
        Print PDF
      </v-btn>
      <v-btn
        color="primary"
        class="print-hidden"
        @click="sendConfirm"
        :disabled="incompleteInvoice"
      >
        Send Invoice
      </v-btn>
    </div>
    <v-dialog
      v-model="confirmSend"
      scrollable
      persistent
      :overlay="false"
      max-width="500px"
      transition="dialog-transition"
    >
      <v-card class="confirm-send-dialog">
        <h1>Send invoice?</h1>
        <p>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce
          placerat cursus imperdiet. Aliquam condimentum mattis ex eget
          elementum. Sed lorem enim, feugiat id augue eu, aliquam ultricies
          magna.
        </p>
        <div class="split-field">
          <v-spacer></v-spacer>
          <v-btn color="lightGrey" @click="close"> Cancel </v-btn>
          <v-btn color="primary" @click="sendInvoice"> Send Invoice </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { GetProfile } from "@/api/profile"
import { GetAccepted } from "@/api/requests/accepted"
import CompanyInfo from "@/components/billing/company-info.vue"
import InvoiceInfo from "@/components/billing/invoice-info.vue"
import InvoiceItems from "@/components/billing/invoice-items.vue"
import { DateTime } from "luxon"

export default {
  components: {
    companyInfo: CompanyInfo,
    invoiceInfo: InvoiceInfo,
    invoiceItems: InvoiceItems
  },

  data() {
    return {
      invoiceItems: [],
      response: {
        company_info: {}
      },
      usernames: [],
      accepted: [],
      client: {
        name: "",
        address: ""
      },
      invoice: {
        reference: "",
        proposalRef: "",
        dueDate: "2021-12-01T13:35:37+0000",
        sentAt: "20/09/2021",
        createdAt: "",
        paidAt: "21/09/2021"
      },
      confirmSend: false,
      invoiceInfoCompleted: false,
      invoiceItemsLength: 0
    }
  },
  methods: {
    async getProfile() {
      let data = await GetProfile()
      let accepted = await GetAccepted()
      this.accepted = accepted
      accepted.forEach(element => {
        this.usernames.push(element.username)
      })

      this.response = {
        company_info: {
          bio: data.company.bio,
          company_name: data.company.company_name,
          logo: data.company.logo,
          tel: data.company.tel,
          website: data.company.website,
          cqc_profile: data.company.cqc_profile,
          address: data.company.address
        },
        contact_email: data.email
      }
    },
    print() {
      window.print()
    },
    sendConfirm() {
      this.confirmSend = true
    },
    sendInvoice() {
      // This will be the stripe stuff
    },
    close() {
      this.confirmSend = false
    },
    completedInvoiceInfo(check) {
      this.invoiceInfoCompleted = check
    },
    invoiceItemsList(len) {
      this.invoiceItemsLength = len
    },
    overdue() {
      let overdue = false
      let dueDate = DateTime.fromISO(this.invoice.dueDate).toFormat(
        "yyyy-MM-dd"
      )
      let today = DateTime.local().toISODate()
      if (this.invoice.paidAt === "" && dueDate < today) {
        overdue = true
      }
      return !overdue
    }
  },
  computed: {
    incompleteInvoice() {
      return !(this.invoiceItemsLength > 0 && this.invoiceInfoCompleted)
    },
    invoiceStatus: {
      get: function() {
        let status = 1
        if (this.invoice.sentAt != null) {
          status = 2
        }
        if (this.invoice.paidAt != null) {
          status = 3
        }
        return status
      }
    }
  },
  async beforeMount() {
    await this.getProfile()
  },
  mounted() {
    this.invoiceItems = this.$refs.invoiceItemTable.internalCurrentItems
  }
}
</script>

<style scoped>
p {
  font-size: 1.5rem;
}
.invoice > div {
  padding: 20px 0;
}
.title {
  font-size: 2rem !important;
  font-weight: 600 !important;
  margin-bottom: 20px;
}
#billTo {
  padding-top: 30px;
}
.split-field {
  display: flex;
  flex-direction: row;
  column-gap: 30px;
}
.split-field > div {
  width: 100%;
}
.half-width {
  width: 50%;
}
.print-show {
  display: none;
}
.confirm-send-dialog {
  padding: 20px;
}
::v-deep .v-stepper__step {
  padding: 0 24px;
  height: fit-content;
  align-self: center;
}
::v-deep .v-stepper__header {
  height: 42px;
}

::v-deep
  .theme--light.v-stepper
  .v-stepper__step:not(.v-stepper__step--active):not(.v-stepper__step--complete):not(.v-stepper__step--error)
  .v-stepper__step__step {
  background-color: white !important;
  border: 2px solid var(--v-grey-base) !important;
}
/* .completed {
  opacity: 0.5;
} */
::v-deep
  div
  .v-stepper__step.v-stepper__step--complete.completed
  > span.v-stepper__step__step {
  opacity: 0.3;
}

@media screen and (max-width: 1919px) {
  p {
    font-size: 1.1rem;
  }
}

@media print {
  .invoice * {
    visibility: visible;
  }
  .invoice {
    position: absolute;
    left: 0;
    top: -100px;
    width: 100%;
  }
  .invoice > div {
    padding: 15px 0;
  }
  p {
    font-size: 1rem !important;
  }
  .print-hidden {
    display: none;
  }
  .print-show {
    display: block;
  }
  @page {
    size: auto;
    margin: 0;
  }
}
</style>
