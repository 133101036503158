<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title>Archived Requests</v-toolbar-title>
    </v-toolbar>
    <v-card-text style="max-height:80vh;overflow-y:auto;">
      <v-list v-if="requests.length > 0" two-line class="list">
        <v-list-item
          v-for="r in requests"
          :key="r.id"
          ripple
          @click="open(r.id)"
        >
          <v-list-item-content>
            <v-list-item-title v-text="`Request: ${r.id}`"></v-list-item-title>
            <v-list-item-subtitle
              >Postcode:
              {{
                r.about.postcode
                  .substring(0, r.about.postcode.length - 3)
                  .toUpperCase()
              }}
              {{
                r.about.postcode
                  .substring(
                    r.about.postcode.length - 3,
                    r.about.postcode.length
                  )
                  .toUpperCase()
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-spacer></v-spacer>
          <small>Request Date {{ r.created_at }}</small>
        </v-list-item>
      </v-list>
      <h3 v-else>You currently have no archived requests</h3>
    </v-card-text>
  </v-card>
</template>

<script>
import { GetArchived } from "@/api/requests/archived"
export default {
  async created() {
    await GetArchived()
  },
  computed: {
    requests() {
      return this.$store.getters.archived
    }
  },
  methods: {
    open(id) {
      this.$router.push(`archived/${id}`)
    }
  }
}
</script>

<style scoped>
.responses {
  height: calc(100vh - (56px + 32px));
}
.list {
  /* height: 35vh; */
  overflow-y: auto;
}
</style>
