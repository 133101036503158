<template>
  <div class="invoice-items">
    <h2 class="title">Invoice Item(s)</h2>
    <v-data-table
      :headers="headers"
      ref="invoiceItemTable"
      hide-default-footer
      disable-pagination
      fixed-header
    >
      <template v-slot:body="props" class="print-show">
        <draggable :list="props.items" tag="tbody" group="services">
          <tr v-for="(item, index) in props.items" :key="index">
            <td>{{ item.name }}</td>
            <td>{{ item.description }}</td>
            <td>
              <v-text-field v-model="item.quantity"></v-text-field>
            </td>

            <td>{{ timePeriod(item.time_period) }}</td>
            <td>
              <v-text-field v-model="item.from_price"></v-text-field>
            </td>
            <td class="print-hidden">
              <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
            </td>
          </tr>
        </draggable>
      </template>
    </v-data-table>
    <p id="noItemText" v-if="this.invoiceItems.length < 1" class="print-hidden">
      Please drag a service to this table
    </p>
    <div class="invoiceTotal">
      <p><span id="total">GBP Total</span> £{{ invoiceTotal }}</p>
    </div>
  </div>
</template>
<script>
import draggable from "vuedraggable"
import { timePeriod } from "@/time-period.js"

export default {
  components: { draggable },

  data() {
    return {
      invoiceItems: [],
      headers: [
        { text: "Name", value: "name", width: "15%", sortable: false },
        {
          text: "Description",
          value: "description",
          width: "45%",
          sortable: false
        },
        { text: "Quantity", value: "quantity", width: "5%", sortable: false },

        {
          text: "Time Period",
          value: "time_period",
          width: "15%",
          sortable: false
        },
        {
          text: "Unit Price (£)",
          value: "price",
          width: "15%",
          sortable: false
        },
        {
          text: "",
          value: "actions",
          width: "5%",
          sortable: false,
          class: "print-hidden"
        }
      ]
    }
  },
  methods: {
    timePeriod(time) {
      return timePeriod(time)
    },
    deleteItem(item) {
      this.editedIndex = this.invoiceItems.indexOf(item)
      this.invoiceItems.splice(this.editedIndex, 1)
    }
  },
  computed: {
    invoiceTotal() {
      let total = []
      this.invoiceItems.forEach(item => {
        total.push(item.quantity * item.from_price)
      })
      return total.reduce((total, num) => total + num, 0)
    }
  },
  mounted() {
    this.invoiceItems = this.$refs.invoiceItemTable.internalCurrentItems
  },
  watch: {
    invoiceItems: {
      handler() {
        this.invoiceItems.forEach((item, index) => {
          item.item_order = index
        })

        this.$emit("invoice-items-length", this.invoiceItems.length)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
p {
  font-size: 1.5rem;
}

.title {
  font-size: 2rem !important;
  font-weight: 600 !important;
  margin-bottom: 20px;
}
#noItemText {
  text-align: center;
}
.invoiceTotal {
  display: flex;
  justify-content: flex-end;
  margin: 20px 80px 0 0;
}
.invoiceTotal p {
  border-bottom: 1px solid black;
}
#total {
  font-weight: 600;
}
.print-show {
  display: none;
}
@media screen and (max-width: 1919px) {
  p {
    font-size: 1.1rem;
  }
}

@media print {
  p {
    font-size: 1rem !important;
  }
  #total,
  .invoiceTotal p {
    font-size: 1.2rem !important;
  }
  .print-hidden {
    display: none;
  }
  .print-show {
    display: block;
  }
}
</style>
