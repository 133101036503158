<template>
  <div class="chat_container">
    <sidebar :chat-i-d="chatID" />
    <chatbox :chat-i-d="chatID" style="width:100%" />
  </div>
</template>

<script>
import Chatbox from "./chat/chatbox.vue"
import Sidebar from "./chat/sidebar.vue"

export default {
  components: {
    chatbox: Chatbox,
    sidebar: Sidebar
  },
  computed: {
    chatID() {
      return this.$route.params.id
    }
  }
}
</script>

<style scoped>
.chat_container {
  display: grid;
  grid-template-columns: 25% auto;
  gap: 25px;
  width: 50%;
  margin: auto;
}

@media screen and (max-width: 1920px) {
  .chat_container {
    width: 75%;
  }
}

@media screen and (max-width: 1366px) {
  .chat_container {
    width: 100%;
  }
}

@media screen and (max-width: 767px) {
  .chat_container {
    grid-template-columns: auto;
  }
  .chat_list {
    display: none;
  }
  .messages p {
    max-width: 80%;
  }
  .message_container {
    height: calc(100vh - 120px);
    width: 100%;
  }
}
</style>
