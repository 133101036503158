import { findPhoneNumbersInText } from "libphonenumber-js"

export const noTel = value => {
  const numbers = findPhoneNumbersInText(value, "GB")
  return (
    numbers.length === 0 ||
    "Cannot include Phone number, it will be added automatically"
  )
}

export const noEmail = value => {
  const pattern = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return (
    !pattern.test(value) ||
    "Cannot include email, it will be added automatically"
  )
}
