<template>
  <div id="request">
    <info
      id="info"
      :about="request.about"
      :care="request.care"
      :physio="request.physio"
      :lat="request.lat"
      :lng="request.lng"
      :updated-at="request.updated_at"
    />
    <div id="proposal-form-container" class="scroll-shadow">
      <proposal-form
        class="proposal-form"
        :client-start-date="client_start_date"
        @responded="handleResponding"
      />
    </div>
    <chat id="chat" :user-i-d="request.user_id" />
  </div>
</template>

<script>
import Info from "./request/info.vue"
import ProposalForm from "./request/Proposal-form.vue"
import Chat from "./request/chat.vue"
// import CqcProfile from "@/components/cqc-profile.vue";

import { SITE, SHOW_CQC } from "@/config"
import { GetRequest } from "@/api/requests/requests"
import { SendMessage } from "@/api/messages/send_message.js"
import { GetMessages } from "@/api/messages/get_messages.js"
import { AddChat } from "@/api/messages/add_chat.js"
import { GetChats } from "@/api/messages/get_chats.js"
import { DateTime } from "luxon"

export default {
  components: {
    // CqcProfile,
    // archive: Archive,
    info: Info,
    "proposal-form": ProposalForm,
    chat: Chat
  },
  data() {
    return {
      show_cqc: SHOW_CQC,
      site: SITE,
      request: {
        user: {
          postcode: ""
        },
        about: {
          accommodation: "",
          preferences: ""
        },
        care: {},
        physio: {}
      },
      chat_id: null,
      message: ""
    }
  },
  computed: {
    client_start_date() {
      if (this.site === "CARE") {
        return this.request.care.start_date_time
      } else if (this.site === "PHYSIO") {
        return DateTime.fromISO(this.request.physio.date).toFormat("dd/MM/yyyy")
      }
      return ""
    }
  },
  async beforeMount() {
    await this.getRequest()
    this.request.about.postcode =
      this.request.about.postcode
        .substring(0, this.request.about.postcode.length - 3)
        .toUpperCase() +
      " " +
      this.request.about.postcode
        .substring(
          this.request.about.postcode.length - 3,
          this.request.about.postcode.length
        )
        .toUpperCase()

    this.chat_id = this.$store.getters.checkChats(
      this.request.user_id,
      this.$store.getters.self
    )
  },
  methods: {
    async getRequest() {
      this.request = await GetRequest(this.$route.params.id)
    },
    async sendMessage() {
      if (this.$refs.form.validate()) {
        this.chat_id = await AddChat(this.request.user_id)
        await GetChats()
        await SendMessage(this.chat_id, this.message)
      }
    },
    async handleResponding() {
      this.chat_id = this.$store.getters.checkChats(
        this.request.user_id,
        this.$store.getters.self
      )
      if (this.chat_id == null) {
        await AddChat(this.request.user_id)
        await GetChats()
      }

      this.$router.push("home/requests")
    },
    async open(id) {
      await GetMessages(id)
      this.$router.push({
        name: "chat",
        params: { id }
      })
    }
  }
}
</script>

<style scoped>
#request {
  margin: 0 20px 0px 20px;
  display: grid;
  max-width: 1500px;
  margin: 0 auto;
  grid-template-areas:
    "info  proposal proposal"
    "info proposal proposal"
    "chat proposal proposal";
  grid-template-columns: 0.5fr 0.5fr 0.5fr;
  grid-template-rows: 400px 100px auto;
  gap: 20px;
  /* Height is 100% of the view port 
  - 64px for the header 
  - 15px for top margin 
  - 15px for the bottom margin */
  /* height: calc(100vh - (64px + 15px + 15px)); */
}
#request > * {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
  padding: 10px 10px 0 10px;
}

#info {
  grid-area: info;
}

#proposal-form-container {
  grid-area: proposal;
  /* border: 1px solid black; */
  overflow-y: auto;
}

.proposal-form {
  padding-right: 20px;
}

#chat {
  grid-area: chat;
  padding-bottom: 10px;
}

#archive {
  grid-area: archive;
}

.loading {
  background-color: cadetblue;
}

li {
  list-style: none;
}

h2 {
  padding-bottom: 10px;
}

strong {
  font-size: 1.3rem;
  margin-bottom: 5px;
}
p {
  font-size: 1.3rem;
}

li {
  font-size: 1.3rem;
}

.message_content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}
.message_content h2 {
  width: 100%;
}

.preferences__label {
  display: inline-block;
  margin-top: 20px;
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
}
.label {
  display: block;
  margin: 20px 0;
  color: rgba(0, 0, 0, 0.54);
  font-size: 16px;
}
.required {
  color: #ff5252;
}
.required-label {
  color: #ff5252;
  font-size: 12px;
}

.datePicker {
  width: 98%;
  margin: 0 0 30px 1%;
}

::v-deep .v-slide-group__prev {
  display: none !important;
}

.scroll-shadow {
  background: linear-gradient(#ffffff 33%, rgba(255, 255, 255, 0)),
    linear-gradient(rgba(255, 255, 255, 0), #ffffff 66%) 0 100%,
    radial-gradient(
      farthest-side at 50% 0,
      rgba(34, 34, 34, 0.5),
      rgba(0, 0, 0, 0)
    ),
    radial-gradient(
        farthest-side at 50% 100%,
        rgba(34, 34, 34, 0.5),
        rgba(0, 0, 0, 0)
      )
      0 100%;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-attachment: local, local, scroll, scroll;
  background-size: 100% 24px, 100% 24px, 100% 8px, 100% 8px;
}

@media only screen and (max-height: 768px) {
  #request {
    margin: 0 35px 0 35px;
    grid-template-areas:
      "info proposal"
      "info proposal"
      "chat proposal";
    grid-template-columns: 0.5fr 1fr;
    gap: 5px;

    grid-template-rows: 325px 75px 170px;
  }

  .proposal {
    overflow-y: auto;
  }
}
</style>
