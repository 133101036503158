export function timePeriod(value) {
  var time_period = ""
  switch (value) {
    case 0:
      time_period = "One Off Payment"
      break
    case 1:
      time_period = "Per Hour"
      break
    case 2:
      time_period = "Per Day"
      break
    case 3:
      time_period = "Per Week"
      break
    case 4:
      time_period = "Per Month"
      break
  }
  return time_period
}

export function timePeriodStore(value) {
  var time_period = 0
  switch (value) {
    case "One Off Payment":
      time_period = 0
      break
    case "Per Hour":
      time_period = 1
      break
    case "Per Day":
      time_period = 2
      break
    case "Per Week":
      time_period = 3
      break
    case "Per Month":
      time_period = 4
      break
  }
  return time_period
}
