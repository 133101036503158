<template>
  <div v-show="show_services" class="services">
    <v-card class="service-table">
      <v-card-title class="service-header"
        ><span id="service-table-title">Services</span>
        <v-btn color="lightGrey" @click="showServiceDialog">Add Service</v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          class="service-table-search"
          v-model="search"
          append-icon="mdi-magnify"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="services"
          :search="search"
          :page.sync="page"
          :items-per-page="rowsPerPage"
          @page-count="pageCount = $event"
          id="service-table"
        >
          <template v-slot:[`item.price`]="{ item }">
            <span v-if="item.to_price !== 0">
              {{ item.from_price }} - {{ item.to_price }}</span
            >
            <span v-else>{{ item.from_price }}</span>
          </template>
          <template v-slot:[`item.timePeriod`]="{ item }">
            <span>
              {{ timePeriod(item.time_period) }}
            </span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="editItem(item)">
              mdi-pencil
            </v-icon>
            <v-icon small @click="deleteItem(item)"> mdi-delete </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog
      v-model="showServiceManagement"
      max-width="600px"
      transition="dialog-transition"
      persistent
    >
      <ServiceManagement
        @newEditedItem="addService"
        @resetPrice="resetPriceRange"
        @closeDialog="closeManagementDialog"
        :edited="this.editedItem"
        :price="this.priceRange"
      ></ServiceManagement>
    </v-dialog>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete this item?</v-card-title
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="lightGrey" @click="close">Cancel</v-btn>
          <v-btn color="primary" @click="deleteItemConfirm">OK</v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import ServiceManagement from "@/components/billing/service-management.vue"
import { SHOW_SERVICES } from "@/config"
import {
  GetServices,
  SendService,
  UpdateService,
  RemoveService
} from "@/api/billing/service"
import { timePeriod } from "@/time-period.js"
export default {
  components: { ServiceManagement },
  data: () => ({
    dialogDelete: false,
    showServiceManagement: false,
    windowWidth: 0,
    headers: [
      { text: "Name", align: "start", value: "name", width: "15%" },
      { text: "Description", value: "description", width: "40%" },
      { text: "Price (£)", value: "price", width: "15%" },
      { text: "Time Period", value: "timePeriod", width: "18%" },
      { text: "", value: "actions", width: "10%", sortable: false }
    ],
    services: [],
    editedIndex: -1,
    editedItem: {},
    removedItem: {},
    defaultItem: {
      description: undefined,
      from_price: undefined,
      to_price: undefined,
      id: undefined,
      name: undefined,
      time_period: undefined
    },
    search: "",
    priceRange: false,
    page: 1,
    pageCount: 0,
    rowsPerPage: 5,
    show_services: SHOW_SERVICES
  }),
  async created() {
    this.services = await GetServices()
  },

  mounted() {
    this.windowWidth = window.innerWidth
  },

  methods: {
    async addService(serviceItem) {
      if (this.editedItem.id === undefined) {
        await SendService(serviceItem)
      } else {
        await UpdateService(serviceItem)
      }
      this.services = await GetServices()
      this.close()
    },

    editItem(item) {
      this.windowWidth = window.innerWidth
      if (item.to_price == 0) {
        this.priceRange = false
      } else {
        this.priceRange = true
      }

      this.editedIndex = this.services.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.showServiceDialog()
    },

    resetPriceRange() {
      this.priceRange = false
    },

    deleteItem(item) {
      this.removedItem = item
      this.editedIndex = this.services.indexOf(item)
      this.priceRange = false
      this.dialogDelete = true
    },

    async deleteItemConfirm() {
      await RemoveService(this.removedItem.id)
      this.services.splice(this.editedIndex, 1)
      this.dialogDelete = false
      this.close()
    },

    close() {
      this.dialogDelete = false
      this.showServiceManagement = false
      this.editedItem = Object.assign({}, this.defaultItem)
      this.editedIndex = -1
    },
    timePeriod(time) {
      return timePeriod(time)
    },
    showServiceDialog() {
      this.windowWidth = window.innerWidth
      this.showServiceManagement = true
    },
    closeManagementDialog() {
      this.close()
    }
  }
}
</script>

<style scoped>
.services {
  display: flex;
  gap: 25px;
}
/* Service List */

.service-table {
  width: 100%;
  height: fit-content;
}

.service-header {
  background-color: var(--v-primary-base);
}

#service-table-title {
  color: #ffffff;
  padding: 0 15px;
  font-size: 1.25rem;
  font-weight: 400;
}

.service-table-search {
  background-color: #ffffff;
  height: 30px;
  margin: 0;
  padding: 0 10px;
  border-radius: 20px;
  max-width: 30%;
}
::v-deep .service-table-search > .v-input__control > .v-input__slot:before {
  border-style: none;
}
::v-deep .service-table-search > .v-input__control > .v-input__slot:after {
  border-style: none;
}

.service-management-card {
  min-width: 30%;
}

@media (min-width: 1264px) {
  .container {
    max-width: 1585px;
  }
  .service-management-dialog {
    visibility: hidden;
  }
}

@media (max-width: 1264px) {
  .service-management-card {
    visibility: hidden;
  }
  .service-table {
    min-width: 100%;
  }
}
</style>
