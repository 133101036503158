import { RequestService } from "@/api/instance"
import { DeAuthSession } from "@/api/de-auth"
import { DateTime } from "luxon"

export const Respond = async (id, proposal) => {
  try {
    await RequestService().post(`/proposal`, { proposal })
  } catch (e) {
    DeAuthSession(e)
    throw e
  }
}

export const UpdateResponse = async proposal => {
  try {
    await RequestService().patch(`/proposal/${proposal.id}`, { proposal })
  } catch (e) {
    DeAuthSession(e)
    throw e
  }
}

export const GetResponses = async () => {
  try {
    let {
      data: { proposals }
    } = await RequestService().get("/proposals/enquiry/all")
    if (proposals != null && proposals.length > 0) {
      for (let p of proposals) {
        p.proposal.enquiry_created_at = new Date(p.proposal.enquiry_created_at)
        p.proposal.created_at = new Date(p.proposal.created_at)
      }
      return proposals
    }
    return []
  } catch (e) {
    DeAuthSession(e)
    throw e
  }
}

export const GetResponse = async id => {
  try {
    let {
      data: { proposal }
    } = await RequestService().get(`/proposal/${id}`)
    proposal.start_date = DateTime.fromISO(proposal.start_date).toLocaleString()
    return proposal
  } catch (e) {
    DeAuthSession(e)
    throw e
  }
}
