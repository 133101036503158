<template>
  <v-card>
    <v-toolbar color="primary" dark>
      <v-toolbar-title
        >Requests
        <v-tooltip top nudge-top="15" nudge-right="200">
          <template v-slot:activator="{ on, attrs }">
            <v-icon v-bind="attrs" v-on="on">mdi-help-circle-outline</v-icon>
          </template>
          <span
            >This list shows incoming requests that you have yet to respond to
            or have yet to archive.</span
          >
        </v-tooltip>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-card-actions>
        <v-btn icon @click="show = !show">
          <v-icon>mdi-tune</v-icon>
        </v-btn>
      </v-card-actions>
    </v-toolbar>
    <v-expand-transition>
      <div v-show="show">
        <v-divider></v-divider>
        <v-card-text>
          <v-text-field
            v-model="search"
            outlined
            label="Search"
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
          <v-subheader class="distance-title">Distance (Miles)</v-subheader>
          <v-slider
            v-model="range"
            :max="30"
            :thumb-size="26"
            prepend-icon="place"
            step="1"
            thumb-label="always"
            ticks
          ></v-slider>
          <v-container class="text-right">
            <v-btn
              class="clear-filter-button"
              color="lightGrey"
              @click="clearFilter()"
            >
              Clear
            </v-btn>
            <v-btn color="primary" @click="refreshRequests()">
              Apply filter
            </v-btn>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
      </div>
    </v-expand-transition>
    <v-card-text>
      <v-data-table
        v-if="requests.length"
        :headers="headers"
        :items="requests"
        :items-per-page.sync="rowsPerPage"
        @click:row="open"
      >
        <template v-slot:[`item.about.postcode`]="{ item }">
          {{
            item.about.postcode
              .substring(0, item.about.postcode.length - 3)
              .toUpperCase()
          }}
          {{
            item.about.postcode
              .substring(
                item.about.postcode.length - 3,
                item.about.postcode.length
              )
              .toUpperCase()
          }}
        </template>
        <template v-slot:[`item.distance`]="{ item }">
          <v-chip :color="colorDistance(item.distance)" dark small>
            {{ item.distance.toFixed(1) }}
          </v-chip>
        </template>

        <template v-slot:[`item.care.type`]="{ item }">
          <CareType :care-type="item.care.type" />
        </template>

        <template v-slot:[`item.created_at`]="{ item }">
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-chip
                :color="colorDate(item.created_at)"
                dark
                small
                v-bind="attrs"
                v-on="on"
              >
                {{ item.displayCreated }}
              </v-chip>
            </template>
            <span>{{ item.displayCreatedTime }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <div class="searching" v-else-if="!requests.length & (searching == true)">
        <v-progress-circular
          indeterminate
          color="primary"
        ></v-progress-circular>
      </div>
      <h3 v-else>You currently have no active requests</h3>
    </v-card-text>
  </v-card>
</template>

<script>
import { GetRequests } from "@/api/requests/requests"
import { DateTime } from "luxon"
import CareType from "@/components/careType.vue"

export default {
  components: { CareType },
  async created() {
    await GetRequests()
  },
  computed: {
    rowsPerPage() {
      if (window.screen.height <= 720) {
        return 5
      }
      return 10
    },
    requests() {
      let r = this.$store.getters.requests
      r.map(x => {
        let dt = DateTime.fromJSDate(x.created_at)
        x.displayCreated = dt.toLocaleString()
        x.displayCreatedTime = dt.toLocaleString(DateTime.DATETIME_FULL)
      })
      return r
    }
  },
  methods: {
    colorDistance(distance) {
      if (distance > 25) return "red darken-1"
      else if (distance > 10) return "orange"
      else return "oliveGreen"
    },
    colorDate(date) {
      let now = DateTime.local()
      let elapsed = now.diff(DateTime.fromJSDate(date), "days").days
      if (elapsed > 5) return "red darken-1"
      else if (elapsed > 2) return "orange"
      else return "oliveGreen darken-1"
    },
    open({ id }) {
      this.$router.push(`requests/${id}`)
    },
    async refreshRequests() {
      this.searching = true
      await GetRequests(this.range, this.search)
      this.searching = false
    },
    clearFilter() {
      this.range = 0
      this.search = ""
      this.searching = true
      GetRequests(this.range, this.search)
      this.searching = false
    }
  },
  data: () => ({
    show: false,
    range: 0,
    search: "",
    searching: false,
    headers: [
      { text: "Username", value: "username", groupable: true },
      { text: "Post Code", align: "center", value: "about.postcode" },
      { text: "Distance (Miles)", align: "center", value: "distance" },
      { text: "Request Date", align: "center", value: "created_at" },
      { text: "Request Type", align: "center", value: "care.type" }
    ]
  })
}
</script>

<style scoped>
.username {
  color: var(--v-secondary-base);
}
.chips * {
  font-size: 0.8rem;
}

::v-deep .v-chip .v-chip_content {
  gap: 10px !important;
}

.responses {
  height: calc(100vh - (56px + 32px));
}

.req {
  display: flex;
  justify-content: space-between;
}

.searching {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media only screen and (max-width: 750px) {
  .req {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}
.location_details {
  display: flex;
  align-items: center;
}

.distance-title {
  margin-bottom: 15px;
}

.clear-filter-button {
  margin-right: 10px;
}

@media only screen and (max-width: 360px) {
  .clear-filter-button {
    margin-right: 10px;
    margin-bottom: 10px;
  }
  #filter-title {
    word-break: break-word;
  }
}
.v-list-item__title {
  white-space: normal;
  padding-bottom: 12px;
}

.v-list-item__content {
  align-self: unset;
}

.v-list-item {
  min-height: 0;
}
</style>
