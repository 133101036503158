import { MessageService } from "@/api/instance"
import { DeAuthSession } from "@/api/de-auth"

export const AddChat = async user_id => {
  try {
    let {
      data: { chat_id }
    } = await MessageService().post(`/chats`, { user_id })
    return chat_id
  } catch (e) {
    DeAuthSession(e)
    throw e
  }
}
